import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PatientInfo from './apollo/PatientInfo';
import Admin from './Admin';
import CodingHome from './coding/CodingHome';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/apollo/:pid/:user" element={<PatientInfo />} />
        <Route path="/patient_info/:pid/:user" element={<PatientInfo />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/coding/:patient_id?" element={<CodingHome />} />
      </Routes>
    </Router>
  );
}

export default App;
