import React from 'react';
import { Box, Typography, FormControl, Card, MenuItem, Select, Divider } from '@mui/material';
import { getApolloQualityDiabetes, setApolloQualityDiabetesStatus} from '../../api';
import useFetch from '../../hooks/useFetch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';

const QualityDiabetes = ({ patientId, username, token }) => {
    const { data: patient, loading, error } = useFetch(getApolloQualityDiabetes, patientId, { username, token});
    const [hbdGaps, setHBDGaps] = React.useState([]);
    const [eedGaps, setEEDGaps] = React.useState([]);
    const [kedGaps, setKEDGaps] = React.useState([]);

    React.useEffect(() => {
        if (patient && patient.HBDGaps) {
            setHBDGaps(patient.HBDGaps);
        }
        if (patient && patient.EEDGaps) {
            setEEDGaps(patient.EEDGaps);
        }
        if (patient && patient.KEDGaps) {
            setKEDGaps(patient.KEDGaps);
        }
    }, [patient]);

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography>Error: {error.message}</Typography>;

    const handleHBDGaps = (event, hedis_year) => {
        setApolloQualityDiabetesStatus(patientId, { username, token}, 'HBD', hedis_year, 'ACK');
        setHBDGaps(prevState => prevState.map(gap =>
            gap.HedisYear === hedis_year ? { ...gap, Status: 'ACK' } : gap
        ));
    };

    const handleEEDGaps = (event, hedis_year) => {
        setApolloQualityDiabetesStatus(patientId, { username, token}, 'EED', hedis_year, 'ACK');
        setEEDGaps(prevState => prevState.map(gap =>
            gap.HedisYear === hedis_year ? { ...gap, Status: 'ACK' } : gap
        ));
    };

    const handleKEDGaps = (event, hedis_year) => {
        setApolloQualityDiabetesStatus(patientId, { username, token}, 'KED', hedis_year, 'ACK');
        setKEDGaps(prevState => prevState.map(gap =>
            gap.HedisYear === hedis_year ? { ...gap, Status: 'ACK' } : gap
        ));
    };

    if (patient.ActiveGap === 0 && patient.ActiveRec === 0) {
      return (<div></div>)
    }

    let hbdGapComponent = ""
    let eedGapComponent = ""
    let kedGapComponent = ""

    let diabetesEvidence = ""

    let hasInfo = 0
    if (patient.ActiveHBD === 1) {
        hasInfo = 1
        hbdGapComponent = (<div><Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            bgcolor="teal"
            color="white"
            p={1}
            borderRadius="4px 4px 0 0"
        >
            HbA1c Testing and Control (HBD)
        </Box>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableBody>
                    {hbdGaps.map(gap => {
                        diabetesEvidence = gap.DiabetesEvidence
                        let message = (<></>)
                        if (gap.A1cResult === "") {
                            message = (
                                <>
                                  HbA1c tested in {gap.HedisYear}:{" "}
                                  <Typography component="span" sx={{ color: "#FF8C00", fontWeight: "bold" }}>
                                    missing
                                  </Typography>
                                </>
                              );
                        } else {
                            message = (
                                <>
                                  Latest HbA1c ({gap.A1cDos}) = {gap.A1cResult}{", "}
                                  <Typography component="span" sx={{ color: "#FF8C00", fontWeight: "bold" }}>
                                    out of control
                                  </Typography>
                                </>
                              );
                        }
                        return (
                            <TableRow key={gap.HedisYear} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell sx={{ padding: '4px' }} component="th" scope="row">
                                    {message}
                                </TableCell>
                                <TableCell sx={{ padding: '4px' }} align="right">
                                    <FormControl variant="standard" sx={{ m: 0, minWidth: 46 }}>
                                    <Checkbox
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                        onChange={(event) => handleHBDGaps(event, gap.HedisYear)}
                                    />
                                    </FormControl>
                                </TableCell>
                            </TableRow>
                        )
                    }
                )}
                </TableBody>
            </Table>
        </TableContainer>
        <Divider sx={{ height:10 }}/></div>)
    } 

    if (patient.ActiveEED === 1) {
        hasInfo = 1
        eedGapComponent = (<div><Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            bgcolor="teal"
            color="white"
            p={1}
            borderRadius="4px 4px 0 0"
        >
            Diabetic Eye Exam (EED)
        </Box>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableBody>
                    {eedGaps.map(gap => {
                        diabetesEvidence = gap.DiabetesEvidence
                        let message = (
                            <>
                              Diabetic Eye Exam in {gap.HedisYear}:{" "}
                              <Typography component="span" sx={{ color: "#FF8C00", fontWeight: "bold" }}>
                                missing
                              </Typography>
                            </>
                          );
                        return (
                            <TableRow key={gap.HedisYear} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell sx={{ padding: '4px' }} component="th" scope="row">
                                    {message}
                                </TableCell>
                                <TableCell sx={{ padding: '4px' }} align="right">
                                    <FormControl variant="standard" sx={{ m: 0, minWidth: 46 }}>
                                    <Checkbox
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                        onChange={(event) => handleEEDGaps(event, gap.HedisYear)}
                                    />
                                    </FormControl>
                                </TableCell>
                            </TableRow>
                        )
                    }
                )}
                </TableBody>
            </Table>
        </TableContainer>
        <Divider sx={{ height:10 }}/></div>)
    } 

    if (patient.ActiveKED === 1) {
        hasInfo = 1
        kedGapComponent = (<div><Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            bgcolor="teal"
            color="white"
            p={1}
            borderRadius="4px 4px 0 0"
        >
            Kidney Health Evaluation (KED)
        </Box>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableBody>
                    {kedGaps.map(gap => {
                        diabetesEvidence = gap.DiabetesEvidence

                        let messageEGFR = (
                            <>
                              eGFR tested in {gap.HedisYear}:{" "}
                              {gap.EGFRStatus === "" ? (
                                <Typography component="span" sx={{ color: "#FF8C00", fontWeight: "bold" }}>
                                  missing
                                </Typography>
                              ) : (
                                gap.EGFRStatus
                              )}
                            </>
                          );
                          
                          let messageUACR = (
                            <>
                              uACR tested in {gap.HedisYear}:{" "}
                              {gap.UACRStatus === "" ? (
                                <Typography component="span" sx={{ color: "#FF8C00", fontWeight: "bold" }}>
                                  missing
                                </Typography>
                              ) : (
                                gap.UACRStatus
                              )}
                            </>
                          );

                        return (
                            <TableRow key={gap.HedisYear} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell sx={{ padding: '4px' }} component="th" scope="row">
                                    {messageEGFR}<br/>
                                    {messageUACR}
                                </TableCell>
                                <TableCell sx={{ padding: '4px' }} align="right">
                                    <FormControl variant="standard" sx={{ m: 0, minWidth: 46 }}>
                                    <Checkbox
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                        onChange={(event) => handleKEDGaps(event, gap.HedisYear)}
                                    />
                                    </FormControl>
                                </TableCell>
                            </TableRow>
                        )
                    }
                )}
                </TableBody>
            </Table>
        </TableContainer>
        <Divider sx={{ height:10 }}/></div>)
    } 

    if (hasInfo === 0) {
        return (<></>)
    }
    return (
        <div>
            <Accordion defaultExpanded>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ backgroundColor: '#d5dbdb', margin: '0 !important', minHeight: 'unset !important', '& .MuiAccordionSummary-content': { margin: '5px 0 !important' } }}
                >
                    <Typography variant="h6">Quality - Diabetes Management</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: '#eaeded', padding: '12px' }}>
                    <Box><Typography sx={{ fontWeight: 'bold' }}>Diabetes Evidence</Typography><Typography>{diabetesEvidence}</Typography></Box><br/>
                    {hbdGapComponent}
                    {eedGapComponent}
                    {kedGapComponent}
                </AccordionDetails>
            </Accordion>
            <Divider sx={{ height:15 }}/>
        </div>
    );
}

export default QualityDiabetes;
