import React, { useState } from 'react';
import { Drawer, AppBar, Toolbar, IconButton, Typography, Box, Button, CssBaseline } from '@mui/material';
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon } from '@mui/icons-material';

const drawerWidth = 240;

export default function AdminSidebar() {
  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* Top App Bar */}
      <AppBar position="fixed" sx={{ width: `calc(100% - ${open ? drawerWidth : 0}px)`, ml: `${open ? drawerWidth : 0}px` }}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Expandable Sidebar
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Sidebar Drawer */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        anchor="left"
        open={open}
      >
        <Toolbar>
          <IconButton onClick={handleDrawerToggle}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6">Sidebar Content</Typography>
          <Button variant="contained">Option 1</Button>
          <Button variant="contained" sx={{ mt: 2 }}>Option 2</Button>
        </Box>
      </Drawer>

      {/* Main Content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          padding: 3
        }}
      >
        <Toolbar />
        <Typography paragraph>
          This is the main content area. The sidebar expands and collapses when you click the menu button.
        </Typography>
      </Box>
    </Box>
  );
}
