import { useState, useEffect, useMemo } from 'react';

function flattenArray(arr) {
  return arr.reduce((acc, item) => {
    if (Array.isArray(item)) {
      // If the item is an array, recursively flatten it
      return acc.concat(flattenArray(item));
    } else if (typeof item === 'object' && item !== null) {
      // If the item is an object, recursively flatten its values
      return acc.concat(flattenArray(Object.values(item)));
    } else {
      // Otherwise, it's a primitive value (like an integer), so add it to the result
      return acc.concat(item);
    }
  }, []);
}

const useFetch = (apiFunc, ...params) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const memoizedParams = useMemo(() => params, [flattenArray(params).join(',')]);
  console.log(params);

  useEffect(() => {
    const fetchData = async () => {
      console.log('Fetching data with params:', memoizedParams);
      try {
        const result = await apiFunc(...params);
        setData(result);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiFunc, memoizedParams]);

  return { data, loading, error };
};

export default useFetch;
