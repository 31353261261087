import React from 'react';
import { Box, Typography, FormControl, Card, MenuItem, Select, Divider } from '@mui/material';
import { getApolloMedicationAdherence, setApolloMedicationAdherenceStatus } from '../../api';
import useFetch from '../../hooks/useFetch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

const MedicationAdherence = ({ patientId, username, token }) => {
    const { data: patient, loading, error } = useFetch(getApolloMedicationAdherence, patientId, { username, token});
    const [medications, setMedications] = React.useState([]);

    React.useEffect(() => {
        if (patient && patient.Medications) {
            setMedications(patient.Medications);
        }
    }, [patient]);

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography>Error: {error.message}</Typography>;

    const handleAckMA = (event, medicationName) => {
        setApolloMedicationAdherenceStatus(patientId, { username, token}, medicationName, 'ACK');
        
        setMedications(prevState => prevState.map(ma =>
            ma.MedicationName === medicationName ? { ...ma, Status: 'ACK' } : ma
        ));
    };

    const handleChangeMA = (event, medicationName) => {
        const { value } = event.target;
        setApolloMedicationAdherenceStatus(patientId, { username, token}, medicationName, value);
        
        setMedications(prevState => prevState.map(ma =>
            ma.MedicationName === medicationName ? { ...ma, Status: value } : ma
        ));
    };

    if (patient.Active === 0) {
        return (<div></div>)
    }

    let maComponent = (<div>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
            <TableBody>
                {medications.map((ma, index) => {
                    let alertColor = "";
                    let medStatus = ma.Status;
                    if (ma.CoverageStatus == 'covered') {
                        alertColor = "#d5f5e3"; 
                        medStatus = "Covered";
                    }
                    if (ma.CoverageStatus == 'at risk') {
                        alertColor = "#fef9e7";
                        if (medStatus == "Covered") {
                            medStatus = "None";
                        }
                    } 
                    if (ma.CoverageStatus == 'expired') {
                        alertColor = "#fdedec";
                        if (medStatus == "Covered") {
                            medStatus = "None";
                        }
                    }

                    let statusForm = (<></>);
                    if (medStatus !== "Covered") {
                        statusForm = (
                            <FormControl variant="standard" sx={{ m: 0, minWidth: 46 }}>
                                  <Checkbox
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                    onChange={(event) => handleAckMA(event, ma.MedicationName)}
                                  />
                            </FormControl>
                        );
                    }
                    let deactivationDate = ma.DeactivationDate;
                    let deactivationCode = (<></>);
                    if (deactivationDate) {
                        statusForm = (
                            <FormControl variant="standard" sx={{ m: 0, minWidth: 46 }}>
                                  <Button variant="outlined" disabled>Stopped</Button>
                            </FormControl>
                        );
                        deactivationCode = "Stopped on " + deactivationDate + " by " + ma.DeactivatedBy;
                    }

                    return (
                        <React.Fragment key={ma.MedicationName}>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ padding: '3px', bgcolor: 'darkgray' }} component="th" scope="row"></TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ padding: '8px', bgcolor: 'lightgray' }} component="th" scope="row">
                            <Typography component="span" sx={{ fontWeight: 'bold' }}>
                                {ma.MedicationName} ({ma.DaysSupply} days)
                            </Typography>
                            </TableCell>
                            </TableRow>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ padding: '8px', bgcolor: alertColor }} align="left">
                                <Box display="flex" alignItems="center">
                                <Box sx={{ flexGrow: 1 }}>
                                    Last Fill Date: {ma.MostRecentFillDate}<br/>Coverage End: {ma.CoverageEndDate} <br />{deactivationCode}
                                </Box>
                                {statusForm}
                                </Box>
                            </TableCell>
                            </TableRow>
                        </React.Fragment>
                        )
                    }
                )
            }
            </TableBody>
            </Table>
        </TableContainer>
    </div>)

    return (
        <div>
            <Accordion defaultExpanded>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{ backgroundColor: '#d5dbdb', margin: '0 !important', minHeight: 'unset !important', '& .MuiAccordionSummary-content': { margin: '5px 0 !important' } }}
                >
                    <Typography variant="h6">Medication Adherence</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: '#eaeded', padding: '12px' }}>
                    {maComponent}
                </AccordionDetails>
            </Accordion>
            <Divider sx={{ height:10 }}/>
        </div>
    );
}

export default MedicationAdherence;
